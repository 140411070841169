:root {

    --primary: #228b22;
    --primary_light:#e5edea;
    --secondary: #78909c;
    --secondary_light: #ebebeb;
    --text-color: #37474f;

    --black:#333333;
    --black_two:#000000;
    --black_three:#222222;
    --black_6:#00000006;
    --black_10:#0000001a;
    --black_14:#00000024;
    --ligth_blue: #f5fbff;
    --blue: #7abffc;
    --cloudy_blue:#d7dfe6;
    --cloudy_blue_30:#d7dfe630;
    --cornflower_blue:#555ed4;
    --light-purple:#a3abde;
    --light_purple_40:hsl(232, 100%, 94%);
    --purple: #555ed4;
    --cool_grey:#aeaeba;
    --cool_grey_60:#aeaeba60;
    --cool_grey_two: #8d9293;
    --dark_grey_blue: #2e3b4e; 
    --dark_grey_blue_8:rgba(46, 59, 78, 0.08);
    --dark_grey_blue_4:#2e3b4e0a;
    --organge:#ff8c21;
    --golden_yellow:#ffc321;
    --golden_yellow_light:#ffc32140;
    --grey_blue:#68788c;
    --primary_super_light:#fafffd;
    --lipstick:#d92169;
    --lipstickligth:#d9216960;
    --ligth_lipstick_20:#d9216920;
    --slate:#424e61;
    --slate_grey: #5f6a7d;
    --greyish_brown: #5b5b5b;
    --white: #ffffff;
    --white_65: rgba(255, 255, 255, 0.65); 
    --white__0:rgba(255, 255, 255, 0);
    --disable: #bdbdbd;

}


.c_secondary {
    color: var(--secondary) !important;
}

.c_secondary_light {
    color: var(--secondary_light) !important;
}

.c_black {
    color: var(--black) !important;
}

.c_black_two {
    color: var(--black_two) !important;
}

.c_black_three {
    color: var(--black_three) !important;
}

.c_black_10 {
    color: var(--black_10) !important;
}

.c_ligth_blue {
    color: var(--ligth_blue) !important;
}

.c_primary {
    color: var(--primary) !important;
}

.c_primary_light {
    color: var(--primary_light) !important;
}

.c_text_color {
    color: var(--text-color) !important;
}

.c_cloudy_blue {
    color: var(--cloudy_blue) !important;
}

.c_cloudy_blue_30 {
    color: var(--cloudy_blue_30) !important;
}
.c_cool_grey {
    color: var(--cool_grey) !important;
}
.c_cool_grey_two {
    color: var(--cool_grey_two) !important;
}

.c_cornflower_blue {
    color: var(--cornflower_blue) !important;
}

.c_dark_grey_blue {
    color: var(--dark_grey_blue) !important; 
}

.c_dark_grey_blue_8 {
    color: var(--dark_grey_blue_8) !important;
}

.c_dark_grey_blue_4 {
    color: var(--dark_grey_blue_4) !important;
}

.c_golden_yellow {
    color: var(--golden_yellow) !important;
}

.c_grey_blue {
    color: var(--grey_blue) !important;
}

.c_primary_super_light {
    color: var(--primary_super_light) !important;
}

.c_lipstick {
    color: var(--lipstick) !important;
}

.c_lipstickligth {
    color: var(--lipstickligth) !important;
}

.c_light_purple {
    color: var(--light-purple) !important;
}

.c_purple {
    color: var(--purple) !important;
}

.c_text_color {
    color: var(--slate) !important;
}

.c_text_color_grey {
    color: var(--slate_grey) !important;
}

.c_greyish_brown {
    color: var(--greyish_brown) !important;
}

.c_white {
    color: var(--white) !important;
}

.c_white_65 {
    color: var(--white_65) !important; 
}

.c_white__0 {
    color: var(--white__0) !important;
}

.c_disabled {
    color: var(--disable) !important;
}

.c_orange {
    color: var(--organge) !important;
}


.bg_black {
    background-color: var(--black) !important;
}

.bg_black_two {
    background-color: var(--black_two) !important;
}

.bg_black_three {
    background-color: var(--black_three) !important;
}

.bg_black_10 {
    background-color: var(--black_10) !important;
}

.bg_black_6 {
    background-color: var(--black_6) !important;
}


.bg_transparent {
    background-color: transparent !important;
}

.bg_ligth_blue {
    background-color: var(--ligth_blue) !important;
}

.bg_light-purple {
    background-color: var(--light-purple) !important;
}

.bg_light_purple_40 {
    background-color: var(--light_purple_40) !important;
}

.bg_purple {
    background-color: var(--purple) !important;
}

.bg_primary {
    background-color: var(--primary) !important;
}

.hover_primary:hover{
    background-color: var(--primary_light) !important;
}

.bg_primary_light {
    background-color: var(--primary_light) !important;
}
.bg_secondary {
    background-color: var(--secondary) !important;
}

.bg_secondary_light {
    background-color: var(--secondary_light) !important;
}
.bg_cloudy_blue {
    background-color: var(--cloudy_blue) !important;
}

.bg_cloudy_blue_30 {
    background-color: var(--cloudy_blue_30) !important;
}

.bg_cool_grey {
    background-color: var(--cool_grey) !important;
}

.bg_cool_grey_two {
    background-color: var(--cool_grey_two) !important;
}

.bg_cornflower_blue {
    background-color: var(--cornflower_blue) !important;
}

.bg_dark_grey_blue {
    background-color: var(--dark_grey_blue) !important; 
}

.bg_dark_grey_blue_8 {
    background-color: var(--dark_grey_blue_8) !important;
}

.bg_dark_grey_blue_4 {
    background-color: var(--dark_grey_blue_4) !important;
}

.bg_golden_yellow {
    background-color: var(--golden_yellow) !important;
}

.bg_grey_blue {
    background-color: var(--grey_blue) !important;
}

.bg_primary_super_light {
    background-color: var(--primary_super_light) !important;
}

.bg_lipstick {
    background-color: var(--lipstick) !important;
}

.bg_lipstickligth{
    background-color: var(--lipstickligth) !important;
}

.hover_lipstickligth:hover{
    background-color: var(--lipstickligth) !important;
}

.bg_ligth_lipstick_20 {
    background-color: var(--ligth_lipstick_20) !important;
}

.bg_golden_yellow_light {
    background-color: var(--golden_yellow_light) !important;
}

.bg_slate {
    background-color: var(--slate) !important;
}

.bg_slate_grey {
    background-color: var(--slate_grey) !important;
}

.bg_white {
    background-color: var(--white) !important;
}

.bg_white_65 {
    background-color: var(--white_65) !important; 
}

.bg_white__0 {
    background-color: var(--white__0) !important;
}

.bg_disabled {
    background-color: var(--disable) !important;
}

.bg_text_color {
    background-color: var(--text-color) !important;
}

.filter_primary {
    filter: invert(48%) sepia(40%) saturate(839%) hue-rotate(155deg) brightness(99%) contrast(91%);
}
