input[type=button] {
    cursor:pointer;
}

body {
    overscroll-behavior: contain;
}

.upper > div > input { text-transform: uppercase; }
span.upper {text-transform: uppercase; }
div.upper {text-transform: uppercase; }
.upper {text-transform: uppercase; }
.no_uppercase {text-transform: none !important;}
.capitalize {text-transform: capitalize !important;}

button {
    cursor:pointer;
}

.float_right {
    float: right;
}

.button_float {
    position: fixed !important;
    bottom: 16px;
    right: 32px;
}

.button_float_over {
    position: fixed !important;
    bottom: 78px;
    right: 32px;
}

.button_float_40 {
    position: fixed !important;
    bottom: 10px;
    left: 38%;
}

.button_float_50 {
    position: fixed !important;
    bottom: 10px;
    left: 47%;
}



.hand {
    cursor:pointer;
}

.fullscreen {
    width: 100vw;
    height: 100vh;
}

.height100 {
    height: 100% !important;
}

.height50px {
    height: 50px;
}

.wh100 {
    height: 100vh !important;
}

.minHeight32 {
    min-height: 32px;
}

.minHeight96 {
    min-height: 96px;
}

.container {
    max-width: 1280px !important;
}

.width50 {
    width: 50%;
}

.width75 {
    width: 75%;
}


.width100 {
    width: 100%;
}

.w-100 {
    width: 100% !important;
}

.width50px {
    width: 50px;
}


.width64px {
    width: 64px;
}

.width33 {
    width: 33% !important;
}

.bottom_resposible {
    bottom: 0;
}

.display_flex {
    display: flex !important;
}

.flex_grow_1 {
    flex-grow: 1;
}

.flex_full {
    flex: 1 1 100%;
}

.flex_20 {
    flex: 0 0 20%;
}

.flex_40 {
    flex: 0 0 40%;
}

.flex_50 {
    flex: 0 0 50%;
}

.flex_60 {
    flex: 0 0 60%;
}

.flex_80 {
    flex: 0 0 80%;
}

.flex_1 {
    flex: 1 !important;
}

.flex_11 {
    flex: 1 1;
}

.flex_auto {
    flex: 1 1 auto;
}

.flex_direction_column {
    flex-direction: column
}

.flex_normal_column {
    display: flex;
    flex-direction: column;
    flex: 0 1;
}

.flex_scroll_column {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
}

.flex_header {
    flex: 0 1 auto;
}

.flex_footer {
    flex: 0 1 40px;
}

.fixed_footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
}

.flex_full_row {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
}

.flex_full_column {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
}

.flex_full_column_reverse {
    display: flex;
    flex-direction: column-reverse;
    flex: 1 1 100%;
}

.flex_auto_row {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
}


.flex_auto_row_reverse {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row-reverse;
}

.flex_auto_column {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
}

.equalHMRWrap {
    flex: 1 1 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
}

.wrap {
    flex: 1 1 100%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
}

.wrap_auto {
    flex: 1 0;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
}

.wrap_flex {
    flex: 0;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
}


.text_wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    word-break: break-word;
}

.center {
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center !important;
    justify-content: center;
}

.overflow_hidden
{
    overflow: hidden;
}

.display_toolbar > svg {
    margin-left: 12px;
}

.position_relative {
    position: relative !important;
}
.position_sticky {
    position: sticky;
    top: 0px;
    z-index: 1000;
}

.align_top {
    align-items: start;
}

.align_center {
    align-items: center;
}

.align_left {
    align-items: left;
}

.align_right {
    align-items: right;
}

.align_botton {
    align-items: flex-end;
}

.vertical_align_bottom {
    flex-direction: column-reverse !important;
}

.align_baseline {
    align-items: baseline;
}

.gap_between_12 {
    justify-content: space-between;
    gap: 12px;
}

.gap_between_4 {
    gap: 4px;
}

.elements_from_right {
    justify-content: flex-end;
}

.elements_center {
    justify-content: center;
}

.elements_separated {
    justify-content: space-between;
}

.text_align_center {
    text-align: center;
}

.text_align_left {
    text-align: left !important;
}

.text_align_right {
    text-align: right !important;
}

.text_align_end {
    flex-direction: row-reverse;
}

.nowrap {
    white-space: pre;
}

.hiddenTextOverflow {
    white-space: nowrap;
    overflow: hidden;
}


.fullWidth {
    width: 100vw;
}

.display_none {
    display: none;
}

.uppercase {
    text-transform: uppercase !important;
}       

.shadow_right {
    box-shadow: inset -1px 0px 0px 0px var(--black_14);
}

.shadow {
    box-shadow: inset 0px -1px 0px 0px var(--black_14);
}

.shadow_down {
    -webkit-box-shadow: 1px 1px 0px 0px var(--black_14);
     box-shadow: 1px 1px 0px 0px var(--black_14);
}

.shadow_down_only {
    /* -webkit-box-shadow: 0px 1px 0px 0px var(--black_14);
     box-shadow: 0px 1px 0px 0px var(--black_14); */
    border-bottom: 1px solid #0000001a;
}


.shadow_primary {
    /* -webkit-box-shadow: 0px 1px 0px 0px var(--black_14);
     box-shadow: 0px 1px 0px 0px var(--black_14); */
    border-bottom: 2px solid var(--primary);
}

.shadow_down_only_dark {
    /* -webkit-box-shadow: 0px 1px 0px 0px var(--black_14);
     box-shadow: 0px 1px 0px 0px var(--black_14); */
    border-bottom: 1px solid #9f9e9e;
}

.shadow_down_only_dark:hover {
    /* -webkit-box-shadow: 0px 1px 0px 0px var(--black_14);
     box-shadow: 0px 1px 0px 0px var(--black_14); */
    border-bottom: 2px solid #212121;
}

.shadow_top_only {
    /* -webkit-box-shadow: 0px 1px 0px 0px var(--black_14);
     box-shadow: 0px 1px 0px 0px var(--black_14); */
    border-top: 1px solid #0000001a;
}

.shadow_right_only {
    border-right: 1px solid #0000001a;
}


.shadow_left_only {
    border-left: 1px solid #0000001a;
}

.boxShadow {
    border-radius: 4px;
    border: 1px solid #d7dfe6;
    box-shadow: 0px 2px 2px 0px #d7dfe6, 0px 0px 2px 0px #d7dfe6;
    margin-bottom: 2px;
}

.boxBorder {
    border-radius: 4px;
    border: 1px solid #d7dfe6;
    margin-bottom: 6px;
}

.boxBorderForm {
    border: solid #d7dfe6;
    border-width: 1px;
    border-radius: 6px;
    padding: 1px;
    margin-bottom: 6px;
}

.boxBorderForm > div {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.border_size_4 {
    border-width: 4px !important
}

.border_radious_4 {
    border-radius: 4px;
}

.border_radious_3 {
    border-radius: 3px;
}

.border_radious_6 {
    border-radius: 6px;
}

.border_radious_24 {
    border-radius: 24px;
}


.borderRadiusLeft {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.borderRadiusRight {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.border_light_gray {
    border: 1px solid #d6d2d21a
}

.border_pea {
    border: 1px solid var(--primary);
}

.border_golden_yellow {
    border: 1px solid #ffc321;
}

.border_lipstick {
    border: 1px solid #d92169;
}

.border_disabled {
    border: 1px solid #bdbdbd;
}

.border_shadow_disabled {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}

.border_slate {
    border: 1px solid #424e61;
}

.border_primary {
    border: 1px solid #228b22;
}

.border_cloudy_blue_30 {
    border: 1px solid var(--cloudy_blue_30) !important;
}

.noshadow {
    box-shadow: none !important;
}

.padding_borders_buttons {
    padding: 4px 12px 4px 12px !important;
}

.padding_borders_text {
    padding: 18px 4px 18px 24px !important;
}

.padding_borders_component {
    padding: 8px 8px 8px 24px !important;
}

.padding_borders_equals {
    padding: 12px 12px 12px 12px !important;
}

.padding_borders_flat {
    padding: 9px 24px 9px 24px !important;
}

.padding_borders {
    padding: 18px 24px 18px 24px !important;
}

.padding_borders_left {
    padding: 18px 0px 18px 24px !important;
}

.padding_24 {
    padding: 24px !important;
}

.padding_4 {
    padding: 4px !important;
}

.padding_6 {
    padding: 6px !important;
}

.padding_0 {
    padding: 0 !important;
}

.padding_left_2 {
    padding-left: 2px;
}

.padding_left_6 {
    padding-left: 6px !important;
}

.padding_right_6 {
    padding-right: 6px !important;
}

.padding_left_12 {
    padding-left: 12px !important;
}


.padding_left_64 {
    padding-left: 64px !important;
}

.padding_right_6 {
    padding-right: 6px !important;
}

.padding_right_12 {
    padding-right: 12px !important;
}

.padding_right_18 {
    padding-right: 18px !important;
}

.padding_right_36 {
    padding-right: 36px;
}

.padding_left_18 {
    padding-left: 18px;
}

.padding_left_24 {
    padding-left: 24px;
}

.padding_right_2 {
    padding-right: 2px;
}

.padding_right_24 {
    padding-right: 24px;
}

.padding_right_64 {
    padding-right: 64px;
}

.padding_left_36 {
    padding-left: 36px;
}

.padding_bottom_6 {
    padding-bottom:  6px;
}

.padding_bottom_9 {
    padding-bottom:  9px;
}

.padding_bottom_12 {
    padding-bottom:  12px;
}

.padding_bottom_16 {
    padding-bottom:  16px;
}

.padding_bottom_18 {
    padding-bottom:  18px;
}

.padding_bottom_24 {
    padding-bottom:  24px;
}

.padding_bottom_36 {
    padding-bottom:  36px;
}

.padding_bottom_48 {
    padding-bottom:  48px;
}

.padding_bottom_64 {
    padding-bottom: 64px;
}

.padding_bottom_264 {
    padding-bottom: 264px;
}

.padding_top_3 {
    padding-top: 3px !important;
}

.padding_top_6 {
    padding-top: 6px !important;
}

.padding_top_12 {
    padding-top: 12px !important;
}

.padding_top_16 {
    padding-top: 16px !important;
}

.padding_top_18 {
    padding-top: 18px !important;
}

.padding_top_24 {
    padding-top: 24px;
}

.padding_top_48 {
    padding-top: 48px;
}

.padding_12 {
    padding: 12px !important;
}

.margin_4 {
    margin: 4px !important;
}

.margin_12 {
    margin: 12px !important;
}

.margin_left_2 { 
    margin-left: 2px !important;
}

.margin_left_6 { 
    margin-left: 6px !important;
}

.margin_left_8 {
    margin-left: -8px;
}

.margin_left_12 { 
    margin-left: 12px !important;
}

.margin_left_18 {
    margin-left: 18px;
}

.margin_left_24 {
    margin-left: 24px;
}

.margin_left_32 {
    margin-left: 32px;
}

.margin_left_64 {
    margin-left: 64px;
}

.margin_left_46 {
    margin-left: 46px !important;
}

.margin_left_72 {
    margin-left: -72px;
}

.margin_top__18 {
    margin-top: -18px !important;
}

.margin_top_0 {
    margin-top: 0px !important;
}

.margin_top_4 {
    margin-top: 4px !important;
}

.margin_top_minus_5 {
    margin-top: -5px;
}

.margin_top_8 {
    margin-top: 8px !important;
}

.margin_top_9 {
    margin-top: 9px !important;
}

.margin_top_6 {
    margin-top: 6px !important;
}

.margin_top_12 {
    margin-top: 12px !important;
}

.margin_top_18 {
    margin-top: 18px !important;
}

.margin_top_24 {
    margin-top: 24px !important;
}

.margin_top_32 {
    margin-top: 32px !important;
}

.margin_top_400 {
    margin-top: 400px;
}

.margin_bottom_minus_5 {
    margin-bottom: -5px;
}

.margin_bottom_5 {
    margin-bottom: 5px !important;
}

.margin_bottom_12 {
    margin-bottom: 12px !important;
}

.margin_bottom_16 {
    margin-bottom: 16px !important;
}

.margin_bottom_24 {
    margin-bottom: 24px !important;
}

.margin_bottom_48 {
    margin-bottom: 48px;
}

.margin_bottom_64 {
    margin-bottom: 64px;
}

.margin_left_10 {
    margin-left: 10%;
}

.margin_left_20 {
    margin-left: 20%;
}

.margin_0 {
    margin: 0 !important;
}

.margin_4 {
    margin: 4px !important;
}

.margin_right_2 { 
    margin-right: 2px !important;
}

.margin_right_6 { 
    margin-right: 6px  !important;
}

.margin_right_12 {
    margin-right: 12px !important;
}

.margin_right_18 {
    margin-right: 18px;
}


.margin_right_24 {
    margin-right: 24px !important;
}


.margin_right_32 {
    margin-right: 32px !important;
}


.margin_right__24 {
    margin-right: -24px;
}

.margin_rigth_45 {
    margin-right: -45px;
}

.min_width_24 {
    min-width: 24px;
}

.min_width_32 {
    min-width: 32px;
}

.z_index_dialog {
    z-index: 5000 !important;
}

.z_index_dialog_over_dialog {
    z-index: 10000 !important;
}

.min_width_400 {
    min-width: 400px;
}

.z_index_dialog_over_2dialog {
    z-index: 15000 !important;
}

.overflow_y_scroll {
    overflow-y: auto;
    overscroll-behavior-y: auto;
}

.overflow_x_scroll {
    overflow-x: auto;
    overscroll-behavior-x: auto;
}

.icon {
    width: 24px;
    height: 24px;
    margin-left: 15px;
}

.logo {
    justify-content: center;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    height: 200px;
    min-height: 200px;
    background-size: 83.33% auto;
}

.img {
    background-size: 30% auto;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.dot18 {
    height: 18px;
    width: 18px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.finalizada {
    background-color: var(--cool_grey);
}

.activa {
    background-color: var(--primary);
}

.vencida {
    background-color: var(--lipstick);
}

.fixedbar {
    height: 48px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 100;
}

.fixedbarcell {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dottedBox {
    border: 0.5px dotted var(--primary);
}

.roundedBox {
    box-shadow: inset 0px 0px 2px 1px var(--black_14);
    border-radius: 4px;
}

.solidBox {
    border: 1px solid var(--black_14);
    width: 64px !important;
    height: 64px !important;
}

.tooltip {
    color: var(--white) !important;
    background-color: var(--primary) !important;
    font-family: 'Barlow', sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 24px !important;
}

.fabProgress {
    position: absolute !important;
    width: 40px !important;
    height: 40px !important;
    z-index: 1;
}

.loadingCircle {
    position: absolute !important;
    width: 32px !important;
    height: 32px !important;
    z-index: 1;
    float: left;
    left: 0px;
}


.fabProgress64 {
    position: absolute !important;
    width: 64px !important;
    height: 64px !important;
    z-index: 1;
}

.avatar {
    width: 24px;
    height: 24px;
}

.tag {
    align-items: center;
    align-content: center;
    padding: 4px 10px 4px 12px;
    background: #2e3b4e;
    color: #fff;
    text-decoration: none;
    border-radius: 24px;
    white-space: pre;
} 

/*
.tag:before{
    margin-top: -3px;
    content: "";
    float: left;
    position: absolute;
    left: -6px;
    width: 16px;
    height: 32px;
    border-color: transparent #2e3b4e transparent transparent;
    border-style: solid;
    border-bottom-left-radius: 48px;
    border-top-left-radius: 48px;
    display: inline-block;
    -moz-border-radius: 7.5px;
    background-color: #2e3b4e;
}

        
.tag:after{
    content: "";
    position: absolute;
    top: 14px;
    left: 6px;
    float: left;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: #fff;
    -webkit-box-shadow: -1px -1px 2px #004977;
    box-shadow: -1px -1px 2px #004977;
}
*/

.imgBackground {
    background-size: 100% auto;
    background-position-y: center;
    background-repeat: no-repeat; 
}

.opacity {
    background-color: rgba(0,0,0,.2) !important;
}

input#comment {
    color: white;
}
.comment > label{
    color: white;
}

.circle {
    border: double 3px transparent !important;
    border-radius: 50% !important;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #fff,#666669) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
}

.circleStatus {
    border-radius: 50%;
    width: 16px;
    height: 16px; 
}

.menuItem64 {
    height: 64px;
}

.always_app_bar {
    display: none !important;
}

.button_float_left {
    position: fixed !important;
    bottom: 16px;
    left: 16px;
}
    

.showbar {
    max-height: calc(100vh - 64px);
}

.MuiListItemText-inset {
    padding-left: 0 !important;
}

.hideVertical {
    display: none !important;
}

.col-lg-3 {
    width: 33%;
}

.MuiFab-sizeSmall {
    width: 40px;
    height: 36px;
}

.minwidth {
    min-width: calc(50% - 10px) !important
}

.width50responsive {
    width: calc(50% - 10px)  !important
} 

.border_radious {
    border-radius: 50%
}

.word_break_all {
    word-break: break-all;
}

.ReactModal__Overlay {
    z-index: 10000 !important;
}

.noboder {
    border: none !important;
}

.min_width_dialog > div > div {
    min-width: 350px;
}

.big_dialog > div > div {
    min-width: 550px;
}

@media (max-width: 1024px) {

    .flex_20 {
        flex: 1 1 40%;
    }

    .col-sm-6 {
        width: 50%;
    }

}

@media (max-width: 800px) {

    .flex_20 {
        flex: 1 1 50%;
    }

    .width50responsive {
        width: 100% !important
    }

}

@media (max-width: 320px) {
    .newcommunication .padding_borders_flat {
        padding: 2px !important;
    }
}

@media (max-width: 600px) {

    .MuiFab-sizeSmall {
        width: 43px;
        height: 34px;
    }

    .active {
        margin-bottom: 60px;
    }

    .bottom_resposible {
        bottom: 48px !important;
    }

    .flex_20 {
        flex: 1 1 100%;
    }

    .flex_40 {
        flex: 1 1 100%;
    }

    .flex_50 {
        flex: 1 1 100%;
    }

    .flex_60 {
        flex: 1 1 100%;
    }

    .showbar {
    }

    .fixedbar {
        position: fixed !important;
        height: 48px;
        bottom: 0;
        right: 0;
        left: 0;
    }

    .button_float {
        position: fixed !important;
        bottom: 80px;
        right: 16px;
    }

    .button_float_over {
        position: fixed !important;
        bottom: 140px;
        right: 16px;
    }

    .hide_app_bar {
        display: none !important;
        z-index: -100 !important;
    }

    .button_float_40 {
        position: absolute !important;
        bottom: 80px;
        right: 16px;
        left: auto;
    }

    .button_float_50 {
        position: absolute !important;
        bottom: 80px;
        right: 16px;
        left: auto;
    }

    .margin_bottom_32_movil {
        margin-bottom: 32px;
    }

    .hide {
        display: none !important;
    }

    .navigationBar > .hideVertical {
        display: flex !important;
    }

    .col-xs-12 {
        width: 100%;
    }

    .minwidth {
        min-width: 100% !important
    }

    .big_dialog > div > div {
        max-width: 350px;
    }

}

@media (min-width: 600px) {

    .logo {
        height: 200px;
        background-size: 50% auto;
    }

    .shadow_right_on_tablet {
        box-shadow: inset -1px 0px 0px 0px var(--black_14);
    }

    .imgBackground {
        background-size: 100% auto;
        background-position-y: center;
        background-repeat: no-repeat;  
        min-width: 600px;
        min-height: 400px;
    }    

    .signPad {
        min-width: 400px;
        min-height: 500px;
    }
}

@media (min-width: 1024px) {

    .logo {
        height: 300px;
        background-size: 40% auto;
    }

}



@media (min-width: 1440px) {

    .logo {
        height: 350px;
        background-size: 30% auto;
    }

}

/* portrait */
@media screen and (orientation:portrait) and (min-width: 600px) {

    .main {
        display: none;
    }	

    .message_rotate {
        display: flex;
    }

}


/* portrait */
@media screen and (orientation:portrait) and (max-width: 600px) {

    .main {
        display: flex;
    }	

    .message_rotate {
        display: none;
    }

}

/* landscape */
@media screen and (orientation:landscape) {
    
    .main {
        display: flex;
    }	

    .message_rotate {
        display: none;
    }

}


.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
}
  
.gu-hide {
    display: none !important;
}

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

.manupulation {
    touch-action: manipulation;
}

not-found-number {
    display: flex !important;
    flex-direction: row !important;
    width: 100vw !important;
    justify-content: center !important;
    align-items: center !important;
}

not-found-p {
    font-size: 1rem !important;
    font-family: "'Barlow', sans-serif" !important;
    color: '#5f6a7d' !important;
    margin: 24px !important;
}

not-found-img {
    display: block !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    width: 100px !important;
    height: 100px !important;
    margin-right: 16px !important;
    margin-left: 16px !important;
}

.underline {
    text-decoration: underline;
}

.none {
    text-decoration: none !important;
}

.react-html5-camera-photo {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    float: left !important;
    z-index: 100000 !important;
    width: 100vw !important;
    height: 100vh !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: black;
}

.react-html5-camera-photo-fullscreen > video {
    -o-object-fit: fill;
    object-fit: contain !important;
}

.react-html5-camera-photo-fullscreen > .display-error {
    display: none !important;
}

#outer-circle {
    position: fixed;
    top: calc(50% - (75px/2));
    right: 75px;
    width: 64px;
    height: 64px;
    border: 4px solid var(--white);
    border-radius: 50%;
    z-index: 100001;
    padding: 3px;
    cursor: pointer;
}

#inner-circle {
    top: 32px !important;
}

@media screen and (orientation:portrait) and (max-width: 600px) {

    #outer-circle {
        top: initial;
        bottom: 75px;
        right: calc(50% - (75px/2));
    }

}

.recipe_photo {
    width: 24px;
    border-radius: 50%;
}

.popup_header_close {
    margin-top: 12px;
    padding-left: 24px;
    padding-right: 12px;
}


.simple-tree {
    border-radius: 0 !important;
}
.simple-tree.simple-tree-view-only {
    height: 200px !important;
    border: none;
}

.simple-tree-dropdown-holder {
    position: absolute !important;
    width: 100% !important;
    bottom: -200px;
    z-index: 100;
    border: 1px solid #aeaeba !important;
    border-radius: 0 !important;
    max-height: 200px !important;
    text-align: left;
    color: var(--text-color);
}

.simple-tree-single-selectbox {
    position: relative;
    cursor: pointer;
    padding:  0 !important;
    padding-right: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.simple-tree-chevron-down  {
    margin-right: 8px !important;
}

.simple-tree-chevron-up  {
    margin-right: 8px !important;
}

.simple-tree-cross  {
    float: right !important;
    position: absolute !important;
    right: 50px;
}


.simple-tree-selected-label {
    border-radius: 4px;
    color: white;
    background-color: var(--primary);
    padding: 4px 12px;
    text-transform: uppercase;
}

.simple-tree-chevron-up {
    border-left: 0.275rem solid rgba(0,0,0,0) !important;
    border-right: 0.275rem solid rgba(0,0,0,0) !important;
    border-bottom: 0.4rem solid gray !important;
}

.simple-tree .simple-tree-chevron-down {
    border-left: 0.275rem solid rgba(0,0,0,0) !important;
    border-right: 0.275rem solid rgba(0,0,0,0) !important;
    border-top: 0.4rem solid gray  !important;
}

.simple-tree-selected-label-watermark {
    background: none;
    padding: 0;
}

.simple-tree-input-container > input {
    font-size: 0.75rem !important;
    border: none;
    border-bottom: 1px solid #aeaeba;
}

.simple-tree-node-chevron-down {
    border-top: 0.3rem solid gray !important;
}

.simple-tree-node-chevron-right {
    border-left: 0.3rem solid gray !important;
}

.simple-tree-node-selected {
    background-color: var(--primary) !important;
    color: white;
}

.simple-tree-node-selected > .simple-tree-node-chevron-container > .simple-tree-node-chevron-down {
    border-top: 0.3rem solid white !important;
}

.simple-tree-node-selected > .simple-tree-node-chevron-container > .simple-tree-node-chevron-right {
    border-left: 0.3rem solid white !important;
}

.simple-tree-node-text {
    font-size: 0.75rem;
}

.simple-tree-with-info {
    min-height: 32px;
}

.ql-indent-1 {
    margin-left: 24px;
}

.ql-indent-2 {
    margin-left: 36px;
}

.ql-indent-3 {
    margin-left: 48px;
}

.info_dynamic_text {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.info_dynamic_text > p {
    margin-top: 2px;
    margin-bottom: 2px;
}