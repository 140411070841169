.loading_container > .MuiBackdrop-root {
    top: 4px !important
}


.loading_block {
    position: fixed;
    top: 4px;
    bottom: 0;
    left: 0;
    right: 0;
}

.iframe_pdf {
    position: fixed;
    top: 4px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.pdf_close {
    position: fixed;
    z-index: 200;
    top: 20px;
    left: 18px;
    background-color: #323639;
    color: white;
}

.video_close {
    position: fixed;
    z-index: 200;
    top: 20px;
    right: 18px;
    color: white;
}