.page_error_number_text {
    font-size: 84px;
    line-height: 27px;
    font-family: 'Barlow Semi Condensed', sans-serif;

}
.barlowcondensed_regular{
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 300;
    letter-spacing: 0;
    font-size: 3rem !important;

}

.barlowcondensed_light_number {
    font-family: 'Barlow Condensed', sans-serif !important;
    font-weight: 300 !important;
    letter-spacing: -2.17 !important;
    font-size: 2.45rem !important;

}


.barlowcondensed_light_number_medium {
    font-family: 'Barlow Condensed', sans-serif !important;
    font-weight: 300 !important;
    letter-spacing: -2 !important;
    font-size: 2.45rem !important;

}

.barlowcondensed_extralight_number {
    font-family: 'Barlow ExtraLight', sans-serif !important;
    font-weight: 300 !important;
    letter-spacing: -2.17 !important;
    font-size: 2rem !important;

}

.barlowcondensed_regular_number {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 300;
    letter-spacing: 0;
    font-size: 1.125rem;
    line-height: 18px;

}

.barlowcondensed_light_percentage {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 300;
    letter-spacing: -1.5;
    font-size: 1.68rem;

}

.barlowcondensed_semibold_percentage {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 600;
    line-height: 24px;
    font-size: 1.68rem;
    letter-spacing: 0;

}


.barlowcondensed_medium {
    font-family: 'Barlow Condensed', sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.9375rem !important;
    letter-spacing: 0 !important;

}

.barlowsemicondensed_bold {
    font-family: 'Barlow Semi Condensed', sans-serif !important;
    font-weight: 700 !important;
    line-height: 27px !important;
    font-size: 1.31rem !important;
    letter-spacing: 0 !important;
}


.barlowsemicondensed_bold_small {
    font-family: 'Barlow Semi Condensed', sans-serif !important;
    font-weight: 700 !important;
    line-height: 27px !important;
    font-size: 0.925rem !important;
    letter-spacing: 0 !important;
}

.barlowsemicondensed_regular {
    font-family: 'Barlow Semi Condensed', sans-serif !important;
    font-weight: 400 !important;
    line-height: 27px !important;
    font-size: 1.5rem !important;
    letter-spacing: 0;

}

.barlowsemicondensed_semibold_link {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: 600;
    line-height: 24px;
    font-size: 0.75rem;
    letter-spacing: 0;

}

.barlowsemicondensed_semibold_button {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: 600;
    font-size: 0.625rem;
    letter-spacing: 0;

}

.barlowsemicondensed_medium {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: 500;
    font-size: 0.625rem;
    letter-spacing: 0;

}


.barlowsemicondensed_title {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: 700;
    font-size: 1.125rem;
    letter-spacing: 0;

}


.barlow_semibold_small {
    font-family: 'Barlow', sans-serif !important;
    font-weight: 700 !important;
    font-size: 0.75rem !important;
    line-height: 24px !important;

}

.barlow_semibold {
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    font-size: 1.125rem;
    letter-spacing: 0;

}

.barlow_semibold_label {
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    font-size: 0.9375rem;
    letter-spacing: 0;

}

.barlow_semibold_button {
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 20px;

}

.barlow_medium {
    font-family: 'Barlow', sans-serif;
    font-weight: 500 !important;
    font-size: 1.125rem !important;
    letter-spacing: 0 !important;

}

.barlow_medium_tree_local {
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    letter-spacing: 0;

}

.barlow_medium_italic {
    font-family: 'Barlow', sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.9375rem !important;
    letter-spacing: 0 !important;
    font-style: italic !important;

}

.barlow_medium_place_holder {
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    letter-spacing: 0;

}

.barlow_medium_subtitle {
    font-family: 'Barlow', sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.9375rem!important;
    letter-spacing: 0 !important;

}

.barlowcondensed_summary_number {
    font-family: 'Barlow', sans-serif;
    font-weight: 300;
    letter-spacing: 0;
    font-size: 2.45rem;

}

.barlow_regular {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 24px;

}

.barlow_regular_title {
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    letter-spacing: 1;

}

.barlow_regular_small {
    font-family: 'Barlow', sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 24px !important;
}

.barlow_regular_smalltext {
    font-family: 'Barlow', sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 15px !important;

}

.barlow_regular_textwrap {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 0.935rem;
}

.barlow_regular_select {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 0.835rem !important;
}

.barlow_regular_message {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 0.935rem;
    line-height: 21px;

}

.barlow_regular_options {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 1.125rem;
    letter-spacing: 0;

}

.barlow_regular_little {
    font-family: 'Barlow', sans-serif;
    font-weight: 400 !important;
    font-size: 0.625rem !important;
    letter-spacing: 0.3 !important;

}

.barlow_regular_subtitle {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 0.625rem;
    letter-spacing: 0.8;

}

.barlow_regular_subtitle_small {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 0.525rem;
    letter-spacing: 0.8;
}

.barlow_italic {
    font-family: 'Barlow', sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.9375rem !important;
    letter-spacing: 0 !important;
    font-style: italic !important;
}

.barlow_italic_medium {
    font-family: 'Barlow', sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    letter-spacing: 0 !important;
    font-style: italic !important;
}

.barlow_italic_small {
    font-family: 'Barlow', sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.625rem !important;
    letter-spacing: 0 !important;
    font-style: italic !important;

}